<template>
  <model title="NavMenu" tag="导航菜单">
    <h3>基础用法</h3>
    <lh-nav-menu>
      <lh-menu-group>
        <div slot="groupName">分组1</div>
        <lh-menu-item>导航1</lh-menu-item>
        <lh-menu-item>导航2</lh-menu-item>
        <lh-menu-item>导航3</lh-menu-item>
      </lh-menu-group>
      <lh-menu-group>
        <div slot="groupName">分组2</div>
        <lh-menu-item>导航1</lh-menu-item>
        <lh-menu-item to="/button">跳转到 Button 界面</lh-menu-item>
        <lh-menu-item to="/navMenu">当前路由</lh-menu-item>
      </lh-menu-group>
      <lh-menu-group>
        <div slot="groupName">分组3</div>
        <lh-menu-item>导航1</lh-menu-item>
        <lh-menu-item>导航2</lh-menu-item>
        <lh-menu-item :active="true">激活</lh-menu-item>
      </lh-menu-group>
    </lh-nav-menu>
    <div>
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code1" :readonly="true"></lh-code-editor>
      </lh-collapse>
    </div>
    <h3>属性参考表</h3>

    <h4>lh-nav-menu:</h4>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>fontSize</lh-td>
        <lh-td>组件字体大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>20px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>fontWeight</lh-td>
        <lh-td>组件字体粗细</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>600</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>字体颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>black</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>activeColor</lh-td>
        <lh-td>激活颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>#409EFF</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>width</lh-td>
        <lh-td>组件宽度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>auto</lh-td>
      </lh-tr>
    </lh-table>

    <h4>lh-menu-group:</h4>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>icon</lh-td>
        <lh-td>分组图标</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
    </lh-table>

    <h4>lh-menu-item:</h4>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>to</lh-td>
        <lh-td>导航路由</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td></lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>active</lh-td>
        <lh-td>是否激活。不设置此属性时，默认根据当前路由更新激活状态。</lh-td>
        <lh-td>Boolean</lh-td>
        <lh-td>true | false</lh-td>
        <lh-td>false</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  name:"NavMenuView",
  components: { Model },
  data(){
    return {
      code1:
        "<template>\n"+
        " <lh-nav-menu>\n"+
        "   <lh-menu-group>\n"+
        "     <div slot=\"groupName\">分组1</div>\n"+
        "     <lh-menu-item>导航1</lh-menu-item>\n"+
        "     <lh-menu-item>导航2</lh-menu-item>\n"+
        "     <lh-menu-item>导航3</lh-menu-item>\n"+
        "   </lh-menu-group>\n"+
        "   <lh-menu-group>\n"+
        "     <div slot=\"groupName\">分组2</div>\n"+
        "     <lh-menu-item>导航1</lh-menu-item>\n"+
        "     <lh-menu-item>导航2</lh-menu-item>\n"+
        "     <lh-menu-item to=\"/navMenu\">当前路由</lh-menu-item>\n"+
        "   </lh-menu-group>\n"+
        "   <lh-menu-group>\n"+
        "     <div slot=\"groupName\">分组3</div>\n"+
        "     <lh-menu-item>导航1</lh-menu-item>\n"+
        "     <lh-menu-item>导航2</lh-menu-item>\n"+
        "     <lh-menu-item :active=\"true\">激活</lh-menu-item>\n"+
        "   </lh-menu-group>\n"+
        " </lh-nav-menu>\n"+
        "</template>"
    }
  }

}
</script>

<style>

</style>